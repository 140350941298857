
import React from 'react';

function Footer() {
    return (
        <footer className="app-footer">
            &copy; 2021 Susanna Hämäläinen
        </footer>
    )
}
export default Footer;